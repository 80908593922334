import React from "react";

// CSS  imports //
import logo from "../../components/img/logo.png";

// Common file imports //
import AppConfig from "../../modals/AppConfig";
import { CheckUserName } from "../../common/Validation";
import User from "../../modals/User";

// Api file imports //
import {
  getEventMailTemplate,
  updateEventMailTemplate,
  createEventMailTemplate,
} from "../../libraries/event";
import Notifications from "../../common/Notifications";
import { Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";

class EventMailTemplateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templateName: "",
      description: "",
      code: "",
      html: "",
      variables: [],
    };
  }

  async componentDidMount() {}

  async componentDidUpdate(prevProps) {
    const id = this.props.editTemplateId;
    if (this.props.status !== prevProps.status && id && this.props.status) {
      this.setState({ status: this.props.status });
      if (id) {
        const response = await getEventMailTemplate(id);
        if (response) {
          const { status, result } = response;
          if (status === "success" && result) {
            this.setState({
              templateName: result.name,
              description: result?.description,
              code: result?.code,
              html: result?.html,
              variables: result?.variables ? JSON.parse(result?.variables) : [],
            });
          }
        }
      }
    }
  }

  handleClose = () => {
    Notifications.setDocType("");
    Notifications.setDocTypeError(0);
    Notifications.setMediaSizeImg("");
    Notifications.setMediaSizeDoc("");
    this.setState(
      {
        templateName: "",
        description: "",
        code: "",
        html: "",
        variables: [],
      },
      () => {
        this.props.closeModel(false);
      }
    );
  };

  validateTemplateName = () => {
    const templateNameError = CheckUserName(this.state.templateName);
    if (templateNameError === 1) {
      this.setState({ templateNameError: "Field empty" });
      return false;
    } else return true;
  };

  validateHtml = () => {
    if (this.state.html.length > 0) {
      return true;
    }
    this.setState({ htmlError: "Field empty" });
    return false;
  };

  // Empty input validation
  ValidateAll = () => {
    const templateNameInput = this.validateTemplateName();
    const htmlInput = this.validateHtml();

    const result = templateNameInput && htmlInput;
    if (!result) AppConfig.showValidationError();
    return result;
  };

  // on submit sign in function
  onSubmitCreate = async (e) => {
    // const id = User.user_id;
    const editId = this.props.editTemplateId;
    e.preventDefault();
    const allValidation = this.ValidateAll();
    if (allValidation) {
      const requestData = {
        name: this.state.templateName,
        description: this.state?.description ? this.state?.description : null,
        code: this.state?.code ? this.state?.code : null,
        html: this.state?.html,
        variables:
          this.state?.variables?.length > 0
            ? JSON.stringify(this.state?.variables)
            : null,
      };
      requestData["user_id"] = User.user_id;
      if (editId) {
        const id = this.props.editTemplateId;
        const response = await updateEventMailTemplate(requestData, id);
        if (response && response.status === "success") {
          this.handleClose();
          AppConfig.setMessage("Template updated successfully", false);
        } else if (response.status === "error") {
          this.handleClose();
          AppConfig.setMessage(response?.result);
        }
      } else {
        const response = await createEventMailTemplate(requestData);
        if (response && response.status === "success") {
          this.handleClose();
          User.setRefresh(true);
          AppConfig.setMessage("Template Created successfully", false);
        } else if (response.status === "error" || response.status === "0") {
          let message = "";
          if ("result" in response) {
            message = response.result.error[0];
          } else {
            message = Object.keys(response.error)[0];
          }
          AppConfig.setMessage(message);
        }
      }
      if (this.props?.afterSubmit) await this.props.afterSubmit();
    }
    return false;
  };

  render() {
    const requiredMark = <span className="asterisk">*</span>;
    return (
      <div>
        <Modal
          size="md"
          className="border-style rounded"
          centered
          show={this.props.status}
        >
          <Modal.Header>
            <div className="form-head w-100 d-flex jc-sb align-center">
              <div className="w-100 d-flex align-center">
                <img src={logo} alt="logo" />
                {this.props.editTemplateId ? (
                  <h3> Update Template</h3>
                ) : (
                  <h3> Create Template</h3>
                )}
              </div>
              <button
                className="popup-button closeText d-flex"
                onClick={this.handleClose}
              >
                <span>
                  <AiOutlineCloseCircle />
                </span>
              </button>
            </div>
          </Modal.Header>
          <div className="p-3">
            <Modal.Body>
              <form
                className="align-items-center event-form"
                onSubmit={this.onSubmitCreate}
              >
                <div className="row jc-sb mb-4">
                  <div className="input-row mb-3">
                    <div className="form-padding mb-3">
                      <label className="d-flex align-center">
                        Template Name <span className="asterisk">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          this.state.templateNameError ? "validationError" : ""
                        }`}
                        id="templateName"
                        placeholder={
                          this.state.templateNameError ||
                          "Enter the Template Name"
                        }
                        value={this.state.templateName}
                        onChange={(e) =>
                          this.setState({
                            templateName: e.target.value,
                            templateNameError: "",
                          })
                        }
                        onFocus={() => {
                          this.setState({ templateNameError: "" });
                        }}
                      />
                    </div>
                  </div>
                  <div className="input-row mb-3">
                    <div className="form-padding mb-3">
                      <label className="d-flex align-center">Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="templateCode"
                        placeholder="Enter the Template Code"
                        value={this.state.code}
                        onChange={(e) =>
                          this.setState({
                            code: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="jc-sb mb-4">
                  <div className="input-row mb-3">
                    <div className="form-padding mb-3">
                      <label>Description</label>
                      <textarea
                        className="form-control text-box mb-3"
                        id="eventDescription"
                        label="Event Description"
                        placeholder="Event Description"
                        type="textarea"
                        onFocus={() => {
                          this.setState({ descriptionError: "" });
                        }}
                        style={{ height: "100px" }}
                        value={this.state.description}
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="jc-sb mb-4">
                  <div className="input-row mb-3">
                    <div className="form-padding mb-3">
                      <label>Paste HTML here {requiredMark}</label>
                      {this.state.htmlError ? (
                        <textarea
                          className="form-control text-box mb-3 validationError"
                          id="html"
                          label="HTML"
                          placeholder="HTML"
                          type="textarea"
                          style={{ height: "100px" }}
                          onFocus={() => {
                            this.setState({ htmlError: "" });
                          }}
                          value={this.state.html}
                          onChange={(e) =>
                            this.setState({ html: e.target.value })
                          }
                        />
                      ) : (
                        <textarea
                          className="form-control text-box mb-3"
                          id="html"
                          label="HTML"
                          placeholder="HTML"
                          type="textarea"
                          onFocus={() => {
                            this.setState({ htmlError: "" });
                          }}
                          style={{ height: "100px" }}
                          value={this.state.html}
                          onChange={(e) =>
                            this.setState({ html: e.target.value })
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="jc-sb mb-4">
                  <div className="input-row mb-3">
                    <div className="form-padding mb-3">
                      <label>Template Preview</label> <br />
                      <iframe
                        className="w-100"
                        src={`data:text/html,${encodeURIComponent(
                          this.state.html
                        )}`}
                      />
                    </div>
                  </div>
                </div>

                {this.props?.saveAccess && (
                  <div className="cta-section">
                    <button
                      type="button"
                      className="btn  event-cta-trans"
                      onClick={this.handleClose}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn  event-cta">
                      {this.props.editTemplateId
                        ? "Update Template"
                        : "Create Template"}
                    </button>
                  </div>
                )}
              </form>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    );
  }
}
export default EventMailTemplateForm;
